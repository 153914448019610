import { FunctionComponent, 
    useCallback, 
    useContext, 
    useEffect, 
    useState } from "react";
import { CertificationTracker } from "./CertificationTracker";
import axios from "../../__shared__/axios"; 
import { ClientContext } from "../../../contexts/ClientContext";
import regression from 'regression';

type ContainerProps = {
clientId: string; 
}

type Metric = {
    name: string;
    value: any[]; 
  }
  
  type DataType = {
    metrics: Metric[];
  }
    
export const Container: FunctionComponent<ContainerProps> = (props) => {

    const { clientItem, setClientItem }  = useContext(ClientContext);
    const { client, setClient }  = useContext(ClientContext);
    const { clientDiscography, setClientDiscography }  = useContext(ClientContext);

    const [numberOfWeeks, _setNumberOfWeeks] = useState<any>(0)
    const [currentConsumption, _setCurrentConsumption] = useState<any>(0)


    const getAlbumConsumption = useCallback(async (albumId: number, name: string) => { 
         try { 
             const body = { 
                 artistId: client.MusicConnectArtistID,
                 albumId,
             }

             const res = await axios.post(`/album/consumption`, body)
             if (res instanceof Error) throw (res); 

         
             if (res["status"] === 200 && res["data"] !== null) { 
                 if (res["data"]["error"])
					throw (res["data"]["error"]); 

                 const data: DataType[] = res["data"];

                 const consumpton =data[7].metrics[0].value.find(_  => _.name =='atd').value
                 const consumptions = [];
                 for (let i = 1; i <= data.length; i++) {
                     consumptions.push({ week: i, value: data[i-1].metrics[0].value.find(_  => _.name =='atd').value })                 
                 }
                 const goals = [500000, 1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000]
                
                 for (const goal of goals) {
                    if (consumpton >= goal) {
                        // If current value already exceeds or equals the goal, skip this goal.
                        continue;
                    }
                     var weekTo = predictWeeks(consumptions, goal);
                     if (weekTo > 0) {
                         _setNumberOfWeeks(weekTo);
                         break;
                     }
                 }
                 _setCurrentConsumption(consumpton);

             }
         } catch (err) { 
             console.log("GET ALBUM US STREAMING err ", err); 
         }
    
    }, [clientItem])

    const getTrackConsumption = useCallback(async (songId: number, name: string) => { 
        try { 
            const body = { 
                artistId: client.MusicConnectArtistID,
                songId
            }

            const res = await axios.post(`/track/consumption`, body)
            if (res instanceof Error) throw (res); 
              
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"]) 
                    throw (res["data"]["error"]); 

                const data: DataType[] = res["data"];

                const consumpton = data[data.length-1].metrics[0].value.find(_  => _.name =='atd').value
                const consumptions: { week: number; value: any; }[] = [];
                for (let i = 1; i <= data.length; i++) {
                    consumptions.push({ week: i, value: data[i-1].metrics[0].value.find(_  => _.name =='atd').value })                 
                }
                const goals = [500000, 1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000]
                
                for (const goal of goals) {
                    if (consumpton >= goal) {
                        // If current value already exceeds or equals the goal, skip this goal.
                        continue;
                    }
                    var weekTo = predictWeeks(consumptions, goal);
                    if (weekTo > 0) {
                        _setNumberOfWeeks(weekTo);
                        break;
                    }
                }
                
                _setCurrentConsumption(consumpton);
            }
        } catch (err) { 
            console.log("GET ALBUM US TOTAL CONSUMPTION err ", err); 
        }
    }, [clientItem])


    const predictWeeks = (data: any, target: any) => 
    {
        const regressionData = data.map((d: any) => [d.week, d.value]);
        const result = regression.linear(regressionData);
        const slope = result.equation[0];
        const intercept = result.equation[1];
        const weeksNeeded = (target - intercept) / slope;

        return Math.ceil(weeksNeeded); // Round up to the nearest whole week
    };

    const calculateCertification = () => {
        if (!!client?.MusicConnectArtistID && !!clientItem) {
            const type = clientDiscography[clientItem]["type"];
            const id =  clientDiscography[clientItem]["id"]; 
            if (type === "Album") { 
                console.log("GETTING DATA "); 
                getAlbumConsumption(id, client);
            } else { 
                getTrackConsumption(id, client); 
            }
        }
    }

    useEffect(() => {
        if (!client || !clientItem) { 
        _setCurrentConsumption(null);
        _setNumberOfWeeks(null);

        }
      }, [client, clientItem]);

    return (
        <CertificationTracker client={client} onCalculate={calculateCertification} numberOfWeeks={numberOfWeeks} currentConsumption={currentConsumption} />
    )
 }