import { FunctionComponent, useContext, useState } from "react";
import RevenueCalculator from "./RevenueCalculator";
import { revenueRates } from "../../__shared__/constants/revenueRates";
import { SelectOption } from "../../__shared__/models/SelectOption";
import { CalculatorContext } from "../../../contexts/CalculatorContext";

type ContainerProps = {
clientId: string; 
}


export const Container: FunctionComponent<ContainerProps> = (props) => {
    const {calculatedValues, setCalculatedValues} = useContext(CalculatorContext);

    const platformOptions = [
        {
            label:'Youtube',
            value: 'Youtube'
        }, 
        {
            label:'Spotify',
            value: 'Spotify'
        },
        {
            label:'Apple',
            value: 'Apple'
        },
        {
            label:'Amazon',
            value: 'Amazon'
        },
        {
            label:'Pandora',
            value: 'Pandora'
        },
        {
            label:'TIDAL',
            value: 'TIDAL'
        }
    ];

    const audioVideoOptions = [ 
        {
            label:'Audio',
            value: 'Audio'
            },   
        {
            label:'Video',
            value: 'Video'
        }   
    ];

    const handleCalculations = (selectedItems: SelectOption[], streamingNumber: any) => {
        var calculatedValues = []
       
        if(!!streamingNumber){
            const rate = revenueRates.find(x => x.key === 'Default')?.value;
            const valueAsNumber = parseInt(streamingNumber, 10);
            if(rate){
                if (!isNaN(valueAsNumber)) {
                    var calculatedValue = rate * valueAsNumber;
                    calculatedValues.push({label: 'Total', value: calculatedValue.toFixed(2)})
                }
            }
        }
   
        var total = 0;
        selectedItems.forEach(item => {
            const rate = revenueRates.find(x => x.key === item.label)?.value;
            if (rate) {
                const valueAsNumber = parseInt(item.value, 10);
                if (!isNaN(valueAsNumber)) {
                    var calculatedValue = rate * valueAsNumber;
                    total += calculatedValue
                    calculatedValues.push({label: item.label, value: calculatedValue.toFixed(2)})
                }
            }
        });
        if(total > 0)
            calculatedValues.push({label: 'Total', value: total.toFixed(2)})

        setCalculatedValues(calculatedValues);
    }

    return (
        <RevenueCalculator platforms={platformOptions} audioVideoOptions={audioVideoOptions} calculatedValues={calculatedValues} onCalculate={handleCalculations}/>
    )
 }