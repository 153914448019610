export const revenueRates = [
{
    key: 'Spotify',
    value: 0.004
},
{
    key: 'Apple',
    value: 0.007
},
{
    key: 'Amazon',
    value: 0.004
},
{
    key: 'Youtube',
    value: 0.00069
},
{
    key: 'Pandora',
    value: 0.0013
},
{
    key: 'TIDAL',
    value: 0.013
},
{
    key: 'Audio',
    value: 0.004
},
{
    key: 'Video',
    value: 0.00069
},
{
    key: 'Default',
    value: 0.0033
}

]