import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import { Container as ConsumptionContainer } from "./components/Dashboard/Consumption/index";
import { Container as RevenueCalculatorContainer } from "./components/Dashboard/RevenueCalculator/index";
import { Container as CertificationTrackerContainer } from "./components/Dashboard/CertificationTracker/index";
import MainLayout from "./components/MainLayout/MainLayout";
import { ClientProvider } from "./contexts/ClientContext";
import { AppProvider } from "./contexts/AppContext";
import ProtectedRoute from "./ProtectedRoute";
import { setAuthenticatedUser } from "./components/__shared__/reducer/user";
import { LoadingProvider, useLoading } from "./contexts/LoadingContext";
import Loader from "./components/Loader/Loader";
import setupInterceptors from "./interceptor/axiosInterceptor";
import { ThemeProvider } from '@mui/material/styles';
import theme from "./components/__shared__/theme/theme";
import { CalculatorProvider } from "./contexts/CalculatorContext";

const App: React.FC = () =>  {
  const dispatch = useDispatch();
  const [finsihedInitialize, setFinsihedInitialize] = useState(false);
  const { setLoading } = useLoading();

  useEffect(() => {
    setupInterceptors(setLoading);
  }, [setLoading]);
  
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      const userFromStorage = JSON.parse(user);
      console.log('userFromStorage', userFromStorage);
      dispatch(setAuthenticatedUser(userFromStorage));
    }
    setFinsihedInitialize(true);
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <AppProvider>
        <ClientProvider>
        <CalculatorProvider>
        <LoadingProvider>
          <Loader />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<MainLayout />}>
                <Route path="/" element={<Navigate to="/consumption" />} />
                <Route path="consumption" element={<ConsumptionContainer weekId={""} />} />        
                <Route path="revenueCalculator" element={<RevenueCalculatorContainer clientId={""} />} />
                <Route path="certificationTracker" element={<CertificationTrackerContainer clientId={""} />} /> 
              </Route>
            </Route>
          </Routes>
          </LoadingProvider>
          </CalculatorProvider>
        </ClientProvider>
      </AppProvider>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
