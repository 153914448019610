import * as React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar";
import "./MainLayout.scss";
import ClientSelector from "../ClientSelector";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { ReactComponent as OpenIcon } from "../../assets/icons/hamburger-menu.svg";

const MainLayout = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <div className="mainLayout">
      <div className="sidebarDrawer">
        <div className="menuHeader">
          <Button className="menuButton" onClick={toggleDrawer(true)}>
            <OpenIcon />
          </Button>
        </div>

        <Drawer
          open={open}
          onClose={toggleDrawer(false)}
          sx={{
            ".menuHeader": {
              padding: "40px 20px 20px",
              background: "#f0f0f0"
            },
            ".menuButton": {
              minWidth: "auto",
              padding: "0",
            },
          }}
        >
          <Sidebar toggleDrawer={toggleDrawer(false)}/>
        </Drawer>
      </div>

      <div className="contentWrapper">
        <div className="sidebarMenu">
          <Sidebar toggleDrawer={toggleDrawer(false)}/>
        </div>

        <div className="contentArea">
          <ClientSelector weekId={""} />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
