
import axios from '../components/__shared__/axios';

let activeRequests = 0;
const setupInterceptors = (setLoading: (loading: boolean) => void) => {
  // Request interceptor
  axios.interceptors.request.use(
    (config) => {
      activeRequests++;
      setLoading(true);
      return config;
    },
    (error) => {
      activeRequests--;
      if (activeRequests === 0) {
        setLoading(false);
      }
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axios.interceptors.response.use(
    (response) => {
      activeRequests--;
      if (activeRequests === 0) {
        setLoading(false);
      }
      return response;
    },
    (error) => {
      activeRequests--;
      if (activeRequests === 0) {
        setLoading(false);
      }
      return Promise.reject(error);
    }
  );
};
  
  export default setupInterceptors;