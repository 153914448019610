// In src/components/ProtectedRoute.js or ProtectedRoute.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectAuthenticatedUser } from './components/__shared__/reducer/user';

const ProtectedRoute: React.FC = () => {
  const { access_token } = useSelector(selectAuthenticatedUser)

  return access_token ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
