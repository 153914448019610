import React from 'react';
import Select, { Props as SelectProps, GroupBase, components, OptionProps } from 'react-select';
import { useTheme } from '@mui/material/styles';
import { SelectOption } from '../models/SelectOption';


const CheckboxOption =  <IsMulti extends boolean>(
    props: OptionProps<SelectOption, IsMulti, GroupBase<SelectOption>>
  ) => {
    return (
        
      <components.Option {...props} className="checkbox-option">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className='custom-checkbox'
        />
        {props.label}
      </components.Option>
    );
  };
  
const CustomSelect = <IsMulti extends boolean = false>(
  props: SelectProps<SelectOption, IsMulti, GroupBase<SelectOption>>
) => {
    const theme = useTheme();
    const isMulti = props.isMulti ?? false;
    return (
      <Select
        {...props}
        styles={theme.reactSelectStyles}
        components={isMulti ? { Option: CheckboxOption as any } : undefined}
      />
    );
  };

export default CustomSelect;
