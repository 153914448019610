import React from 'react';
import { Provider } from 'react-redux';
import { store } from './components/__shared__/store';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/style.scss';
import reportWebVitals from './reportWebVitals';
import Loader from './components/Loader/Loader';
import { LoadingProvider } from './contexts/LoadingContext';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
  );
  
  root.render(
    <React.StrictMode>
      <Provider  store={store}>
    <LoadingProvider>
      <Loader />
      <App />
    </LoadingProvider>
    </Provider>
  </React.StrictMode>,
  );
  
reportWebVitals();