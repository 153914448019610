import React, { FunctionComponent, useContext, useState } from "react";
import {
  Container,
  TextField,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  FormLabel,
} from "@mui/material";
import Select, { MultiValue, OptionProps, components } from "react-select";
import "./RevenueCalculator.scss";
import { SelectOption } from "../../__shared__/models/SelectOption";
import CustomSelect from "../../__shared__/custom/CustomSelect";
import { CalculatorContext } from "../../../contexts/CalculatorContext";

type RevenueCalculatorProps = {
  platforms: SelectOption[];
  audioVideoOptions: SelectOption[];
  calculatedValues: SelectOption[];
  onCalculate: (streamingInputs: SelectOption[], streamingNumber: any) => void;
};

const RevenueCalculator: FunctionComponent<RevenueCalculatorProps> = (
  props
) => {
  const { selectedPlatforms, setSelectedPlatforms } =
    useContext(CalculatorContext);
  const { selectedAudioVideo, setSelectedAudioVideo } =
    useContext(CalculatorContext);
  const { streamingInputs, setStreamingInputs } = useContext(CalculatorContext);
  const { streamingNumber, setStreamingNumber } = useContext(CalculatorContext);

  const [isPlatformSelected, setIsPlatformSelected] = useState(false);

  const handlePlatformChange = (selectedOptions: MultiValue<SelectOption>) => {
    setSelectedPlatforms(selectedOptions as SelectOption[]);
    setSelectedAudioVideo([]);
    setStreamingNumber({});
    setStreamingInputs(
      selectedOptions.map((option: any) => ({ label: option.label, value: "" }))
    );
    // props.onCalculate(streamingInputs, streamingNumber)
  };

  const handleAudioVideoChange = (
    selectedOptions: MultiValue<SelectOption>
  ) => {
    setSelectedAudioVideo(selectedOptions as SelectOption[]);
    setSelectedPlatforms([]);
    setStreamingNumber({});
    setStreamingInputs(
      selectedOptions.map((option: any) => ({ label: option.label, value: "" }))
    );
    // props.onCalculate(streamingInputs, streamingNumber)
  };

  const handleStreamingNumberChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newInputs = [...streamingInputs];
      newInputs[index].value = event.target.value;
      setStreamingInputs(newInputs);
    };

  const handleCalculate = () => {
    // Calculation logic goes here
    console.log("Calculating revenue with:", {
      selectedPlatforms,
      selectedAudioVideo,
      streamingInputs,
      streamingNumber,
    });

    props.onCalculate(streamingInputs, streamingNumber);
  };

  return (
    <div className="content">
      <div className="selectors">
        <FormControl
          sx={{ margin: ".5rem 0", marginRight: "2rem" }}
          className="form-control-custom "
          variant="outlined"
        >
          <FormLabel className="form-label-custom">All platforms</FormLabel>
          <CustomSelect
            isMulti
            name="platforms"
            options={props.platforms}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select platforms"
            value={selectedPlatforms}
            onChange={handlePlatformChange}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
          />
        </FormControl>

        <FormControl className="form-control-custom " variant="outlined"
        sx={{ margin: ".5rem 0"}}>
          <FormLabel className="form-label-custom">Audio/Video</FormLabel>
          <CustomSelect
            isMulti
            name="audioVideo"
            options={props.audioVideoOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select audio or video"
            value={selectedAudioVideo}
            onChange={handleAudioVideoChange}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
          />
        </FormControl>
      </div>
      <div></div>
      {(streamingInputs.length > 0 &&
        streamingInputs.map((item, index) => (
          <FormControl
            style={{ marginTop: "2rem" }}
            className="form-control-custom"
          >
            <FormLabel className="form-label-custom">
              Streaming number {item.label}
            </FormLabel>
            <TextField
              sx={{ "& .MuiInputBase-root": { height: 40 } }}
              variant="outlined"
              placeholder="Enter streaming number"
              value={item.value}
              type="number"
              onChange={handleStreamingNumberChange(index)}
            />
          </FormControl>
        ))) || (
        <FormControl
          style={{ marginTop: "2rem" }}
          className="form-control-custom"
        >
          <FormLabel className="form-label-custom">Streaming number</FormLabel>
          <TextField
            sx={{ "& .MuiInputBase-root": { height: 40 } }}
            variant="outlined"
            type="number"
            placeholder="Enter streaming number"
            value={streamingNumber}
            onChange={(e) => setStreamingNumber(e.target.value)}
          />
        </FormControl>
      )}
      <Button
        className="calculate-button"
        sx={{ textTransform: "none" }}
        variant="contained"
        onClick={handleCalculate}
      >
        Calculate
      </Button>

      <div>
        {props.calculatedValues.map((item) => (
          <div className="revenue-card">
            <div className="label">{item.label}</div>
            <div className="value">${item.value.toLocaleString()}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RevenueCalculator;
