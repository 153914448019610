import { PayloadAction, createSlice } from "@reduxjs/toolkit"; 
import { RootState } from "../../store";


export interface UserState {
    name: string
    email: string | null
    access_token: string
    expire: string
  }
  
  const persistedUserState = localStorage.getItem('user');
  const initialState: UserState = persistedUserState ? JSON.parse(persistedUserState) : {
      name: '',
      email: '',
      access_token: '',
      expire: '',
  };

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      setAuthenticatedUser: (state: UserState, { payload }: PayloadAction<UserState>) => {
        console.log('authSlice:: setAuthenticatedUser: ', payload)
        state.name = payload.name
        state.expire = payload.expire
        state.email = payload.email
        state.access_token = payload.access_token

        localStorage.setItem('user', JSON.stringify(payload));
      },
      resetState: (state: UserState) => {
        state.access_token = ''
        state.expire = ''
        state.name = ''
        state.email = ''
        localStorage.removeItem('user')
      },
    },
  })

export const { setAuthenticatedUser, resetState } = userSlice.actions
export const userReducer = userSlice.reducer
export const selectAuthenticatedUser = (state: RootState) => state.userReducer
