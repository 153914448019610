import React, { createContext, useState, ReactNode } from 'react';

type ClientContextProps = {
  client: any;
  isRefreshed: boolean;
  isAlbumOrTrack: string;
  clientDiscography: any;
  clientItem: any;
  clientOptions: any[];

  setClient: (client: any) => void;
  setClientOptions: (clientOptions: any[]) => void;
  setAlbumOrTrack: (isAlbumOrTrack: string) => void;
  setClientDiscography: (clientDiscography: any) => void;
  setIsRefreshed: (isRefreshed: boolean) => void;
  setClientItem: (clientItem: string) => void;

};

export const ClientContext = createContext<ClientContextProps>({
  client: {},
  isRefreshed: false,
  isAlbumOrTrack: '',
  clientDiscography: {},
  clientItem: {},
  clientOptions: [{}],
  setClient: () => {},
  setClientOptions: () => [{}],
  setAlbumOrTrack: () => {},
  setClientDiscography: () => {},
  setIsRefreshed: () => {},
  setClientItem: () => {},
});

type ClientProviderProps = {
  children: ReactNode;
};

export const ClientProvider: React.FC<ClientProviderProps> = ({ children }) => {
    
  const [client, setClient] = useState<any>();
  const [isRefreshed, setIsRefreshed] = useState<boolean>(false);
  const [isAlbumOrTrack, setAlbumOrTrack] = useState<string>('');
  const [clientDiscography, setClientDiscography] = useState<any>({});
  const [clientItem, setClientItem] = useState<any>();
  const [clientOptions, setClientOptions] = useState<any[]>([{}]);


  return (
    <ClientContext.Provider value={{ client, isAlbumOrTrack, clientDiscography, 
isRefreshed, clientItem, clientOptions,
setClient, setAlbumOrTrack, setClientDiscography, setIsRefreshed, setClientItem, setClientOptions}}>
      {children}
    </ClientContext.Provider>
  );
};
