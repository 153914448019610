import React, { FunctionComponent } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import '../Login/Login.scss';

type LoginProps = {
    codeCreated: boolean; 
    email: string; 
    password: string; 
    code: string; 
    setEmail: (email: string) => void; 
    setPassword: (password: string) => void;
    setCode: (code: string) => void;  
    navigate: (page: string) => void; 
    submit: (event: React.FormEvent<HTMLFormElement>) => void; 
    submitCode: (event: React.FormEvent<HTMLFormElement>) => void; 
}

export const Login: FunctionComponent<LoginProps> = (props) => { 
  return  (
    <div className="login-component">
      <div className="login-container">
        <div className="form-container">
          <h2 className="login-title">Log In</h2>
          <form onSubmit={props.submit} className="login-form">
            <div className="input-field">
              <input 
              type="email" 
              value={props.email}
              onChange={(e) => props.setEmail(e.target.value)}
              id="email"
              name="email"
              placeholder="Email"
              required />
            </div>
            <div className="input-field">
              <input 
              type="password"
              value={props.password}
              onChange={(e) => props.setPassword(e.target.value)}
              id="password"
              name="password"
              placeholder="Password"
             required />
            </div>
            {/* <div className="password-reset">
              <a href="#forgot">Forgot Password?</a>
            </div> */}
            <Button className="login-button" type="submit">Log In</Button>
          </form>
        </div>
        <div className="form-image">
          <div className="text-overlay">
            <h1>ROCNATION</h1>
          </div>
        </div>
      </div>
      </div>   
);
}

function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.rocnation.com/">
          Roc Nation
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }