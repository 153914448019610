import React, { createContext, useState, ReactNode } from 'react';

type AppContextProps = {
  section: string;
  setSection: (section: string) => void;
};

export const AppContext = createContext<AppContextProps>({
  section: '',
  setSection: () => {}
});

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    
  const [section, setSection] = useState<string>('');

  return (
    <AppContext.Provider value={{ section, setSection }}>
      {children}
    </AppContext.Provider>
  );
};
