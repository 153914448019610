import React, { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend } from 'chart.js';

Chart.register(LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend);


const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'GLOBAL ON-DEMAND STREAMS',
      align: 'start' as 'start' | 'center' | 'end',
      color: '#333',
      padding: {
        top: 10,
        bottom: 30,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: false,
      grid: {
        color: '#f0f0f0',
      },
      ticks: {
        callback: (value: any) => formatNumber(value as number),
        maxTicksLimit: 5,
      },
    },
  },
};

export const formatNumber = (num: number): string => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    } else {
      return num.toString();
    }
  };

  
type ChartProps = { 
    chartData: any
}

const ChartComponent: FunctionComponent<ChartProps> = (props) => { 
  const reversedChartData = !!props.chartData ? [...props.chartData].reverse() : [];

    const data = {
        labels: reversedChartData.map((x: any)=> x["Week"]),
        datasets: [
          {
            label: 'Global On-Demand Streams',
            data: reversedChartData.map((x: any)=> x["Global Streaming"]),
            borderColor: '#CCC6BE',
            backgroundColor: '#b0b0b0',
            pointBorderColor: '#b0b0b0',
            pointBackgroundColor: '#b0b0b0',
            pointHoverBackgroundColor: '#b0b0b0',
            pointHoverBorderColor: '#b0b0b0',
            fill: false,
            tension: 0.1,
            borderWidth: 2
          },
        ],
      };

  return (
    <div style={{ height:200, marginTop: '20px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
        <Line data={data} options={options} height={200} />
    </div>
  );
};

export default ChartComponent;
