import React, { useEffect, useState } from "react";
import { Login } from "./Login";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "../__shared__/axios";
import { selectAuthenticatedUser, setAuthenticatedUser } from "../__shared__/reducer/user";
import { useLoginMutation } from "../../services/auth.service";


const Container = () => {

    const [login, { data, error, isLoading }] = useLoginMutation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { name, access_token } = useSelector(selectAuthenticatedUser)

    const [_codeCreated, _setCodeCreated] = useState<boolean>(false);
    const [_email, _setEmail] = useState<string>("");
    const [_password, _setPassword] = useState<string>("");
    const [_code, _setCode] = useState<string>("");
    
    //const { loading, userInfo, error } = useSelector((state) => state.auth)

    useEffect(() =>  {
        if (data && !error) {
          dispatch(setAuthenticatedUser(data))
          navigate('/consumption')
        } else if (error) {
          alert("LOGIN ERROR: Wrong username or password");
          console.log(`LoginPage:: Authentication error`, error)
        }
      }, [data, error, dispatch, navigate])
      
    const handleCodeCreated = () => {
        _setCodeCreated(true);
    }

    const handleCodeDeleted = () => {
        _setCodeCreated(false);
    }

    const handleSetEmail = (email: string) => {
        _setEmail(email);
    }

    const handleSetPassword = (password: string) => {
        _setPassword(password);
    }

    const handleSetCode = (code: string) => {
        _setCode(code);
    }

    const handleNavigate = (page: string) => {
        navigate(`/${page}`);
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const email = formData.get("email")?.toString();
            const password = formData.get("password")?.toString();

           var data = login({ email, password })
           console.log(data)
        }
        catch (err) {
            alert("LOGIN ERROR: " + err);
            console.log("LOGIN ERROR: ", err);
        }
    };


    const handleSubmitCode = (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const code = formData.get("code");
        } catch (err) {
            console.log("SUBMIT CODE ERR: ", err);
        }
    }


    return (
        <Login
            email={_email}
            password={_password}
            code={_code}
            codeCreated={_codeCreated}
            setCode={handleSetCode}
            setEmail={handleSetEmail}
            setPassword={handleSetPassword}
            navigate={handleNavigate}
            submit={handleSubmit}
            submitCode={handleSubmitCode} />
    )
}

export default Container; 