import React, { useState, useEffect } from 'react';
import axios from "../../../__shared__/axios"; 

interface Photo {
  id: number;
  title: { rendered: string };
  source_url: string;
  alt_text: string;
}

interface RecentPhotoProps {
  photoName: string;
  setPhotoUrl: (url: string) => void;
}

const PhotoHolder: React.FC<RecentPhotoProps> = ({ photoName, setPhotoUrl }) => {
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPhoto = async () => {
      try {
        if(!!photoName){
        const response = await axios.get('/image', {
          params: { photoName },
          responseType: 'blob' // Ensure the response is treated as a binary blob
        });

        // Create a local URL for the image blob
        const imageUrl = URL.createObjectURL(response.data);
        setPhotoUrl(imageUrl);
      }
      setError('');

 
      } catch (err) {
        setPhotoUrl("");

        //setPhotoUrl('./../../../assets/rn_logo_black.png');
       // setError('An error occurred while fetching the photo.');
      }
    };

    fetchPhoto();
  }, [photoName]);

  if (error) {
    return <div>{error}</div>;
  }

  return null;
};

export default PhotoHolder;
