import axios from "axios";


const baseURL = process.env.REACT_APP_ENVIRONMENT === "production" ? "https://sea-turtle-app-8927s.ondigitalocean.app" : "http://[::1]:8080/";  

const axiosConfig = axios.create({
    baseURL: baseURL
});

export default axiosConfig;
