import { configureStore } from "@reduxjs/toolkit"; 
import { userReducer } from "../reducer/user";
import { authAPI } from "../../../services/auth.service";


export const store = configureStore({
    reducer: {
        userReducer,
    
        [authAPI.reducerPath]: authAPI.reducer,
      //  [articlesAPI.reducerPath]: articlesAPI.reducer,
      },
      middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([authAPI.middleware]),
    })
    
    export type RootState = ReturnType<typeof store.getState>
    export type AppDispatch = typeof store.dispatch