import React, { FunctionComponent } from "react";
import { Button, FormLabel } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { FormControl } from "@mui/base";
import Select from "react-select";
import "./ClientSelector.scss";
import { useLocation } from "react-router-dom";
import CustomSelect from "../__shared__/custom/CustomSelect";

type ClientSelectorProps = {
  section: string;
  clients: any[];
  client: any;
  discography: { [key: string]: any };
  item: any;
  selectClient: (name: string) => void;
  selectDiscography: (name: string) => void;
  refresh: () => void;
  clear: () => void;
};

const ClientSelector: FunctionComponent<ClientSelectorProps> = (props) => {
  const location = useLocation();

  const clientOptions = props.clients.map((client) => ({
    label: client.Artist,
    value: client.Artist,
  }));

  const songOptions = Object.keys(props.discography)
    .filter((key) => props.discography[key].type === "Track")
    .map((key) => ({ label: key, value: key }));

  const albumOptions = Object.keys(props.discography)
    .filter((key) => props.discography[key].type === "Album")
    .map((key) => ({ label: key, value: key }));

  const discographyOptions = [
    { label: "Songs", options: songOptions },
    { label: "Albums", options: albumOptions },
  ];

  // Define the paths where ClientSelector should be hidden
  const showClientSelectorPaths = ["/consumption", "/certificationTracker"]; // Add paths as needed
  const showRefresh = ["/consumption"];

  // Check if the current path matches any of the paths to hide ClientSelector
  const shouldShowClientSelector = showClientSelectorPaths.includes(
    location.pathname
  );
  const shouldShowClearButton = !showClientSelectorPaths.includes(
    location.pathname
  );
  const shouldShowRefreshButton = showRefresh.includes(location.pathname);

  const paddingClass = shouldShowClientSelector ? "padding-bottom" : "";

  return (
    <header className="header">
      <div className={"title-section " + paddingClass}>
        <div className="title">{props.section}</div>
        {shouldShowClearButton && (
          <Button
            className="clear-button"
            sx={{ textTransform: "none" }}
            variant="contained"
            onClick={() => props.clear()}
          >
            Clear all
          </Button>
        )}
      </div>
      {shouldShowClientSelector && (
        <div className="selectors">
          <div className="selectors-container">
            <FormControl className="form-control client-selector">
              <FormLabel className="form-label-custom">Artist</FormLabel>
              <CustomSelect
                options={clientOptions}
                value={
                  clientOptions.find(
                    (option) => option.value === props.client?.Artist
                  ) || null
                }
                onChange={(option) =>
                  props.selectClient(option ? option.value : "")
                }
                placeholder="Select Artist..."
                isClearable
              />
            </FormControl>
            {!!props.discography ? (
              <FormControl className="form-control client-selector">
                <FormLabel className="form-label-custom">
                  Song or album
                </FormLabel>
                <CustomSelect
                  options={discographyOptions}
                  value={
                    discographyOptions
                      .flatMap((group) => group.options)
                      .find((option) => option.value === props.item) || null
                  }
                  onChange={(option) =>
                    props.selectDiscography(option ? option.value : "")
                  }
                  placeholder="Select Discography..."
                  isClearable
                />
              </FormControl>
            ) : null}
          </div>
          {!!props.client && shouldShowRefreshButton && (
            <Button
              className="refresh-button"
              sx={{ textTransform: "none" }}
              variant="contained"
              startIcon={<RefreshIcon style={{ fontSize: 17 }} />}
              onClick={() => props.refresh()}
            >
              Refresh
            </Button>
          )}
        </div>
      )}
    </header>
  );
};

export default ClientSelector;
