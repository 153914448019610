import { createTheme, ThemeOptions } from '@mui/material/styles';
import { StylesConfig, GroupBase } from 'react-select';
import { SelectOption } from '../models/SelectOption';

const createReactSelectStyles = <IsMulti extends boolean>(): StylesConfig<SelectOption, IsMulti, GroupBase<SelectOption>> => ({
    placeholder: (provided) => ({
        ...provided,
        color: '#c0c0c0',
        fontFamily: 'rocnationFontSuisse, sans-serif',
      }),
});

// Extend the ThemeOptions interface to include reactSelectStyles
declare module '@mui/material/styles' {
  interface Theme {
    reactSelectStyles: StylesConfig<any, any, any>;
  }
  interface ThemeOptions {
    reactSelectStyles?: StylesConfig<any, any, any>;
  }
}

const theme = createTheme({
  // Your Material-UI theme settings
  components: {
    MuiButton: {
        styleOverrides: {
            root: {
              borderRadius: '3px',
              boxShadow: 'none',
              fontFamily: 'rocnationFontSuisse, sans-serif'
            },
          },
    },
    MuiTableCell:{
        styleOverrides: {
            root: {
              fontFamily: 'rocnationFontSuisse',
              fontSize: '10px',
            },
          },
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          backgroundColor: '#CCC6BE',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'rocnationFontSuisse',
          color: '#A1A4A6',
          fontSize: '14px',
        },
      },
    },
    MuiTableRow: {
        styleOverrides: {
          root: {
            fontFamily: 'rocnationFontSuisse',
            '&:nth-of-type(even)': {
              backgroundColor: '#f9f9f9',
            },
            '&:last-child td, &:last-child th': {
              border: 0,
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            minWidth: 650,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            '& th': {
              backgroundColor: '#f9f9f9',
              color: '#333',
              textTransform: 'uppercase',
              textAlign: 'right',
              padding: '8px',
              fontSize: '10px',
              fontFamily: 'rocnationFontSuisse',
            },
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            boxShadow: 'none'
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            '& td': {
              color: '#A1A4A6',
              textAlign: 'right',
              padding: '8px',
            },
            '& tr th': {
              fontFamily: 'rocnationFontSuisse',
            },
            '& tr:last-child td': {
              border: 0,
            },
          },
        },
      },
  },
  reactSelectStyles: createReactSelectStyles(),
});

export default theme;
