import React, { createContext, useState, ReactNode } from 'react';
import { SelectOption } from '../components/__shared__/models/SelectOption';

type CalculatorContextProps = {
  selectedPlatforms: SelectOption[];
  selectedAudioVideo: SelectOption[];
  streamingInputs: SelectOption[];
  calculatedValues:any[];
  streamingNumber: any;

  setSelectedPlatforms: (selectedPlatforms: SelectOption[]) => void;
  setSelectedAudioVideo: (selectedAudioVideo: SelectOption[]) => void;
  setStreamingInputs: (streamingInputs: SelectOption[]) => void;
  setCalculatedValues: (calculatedValues: any[]) => void;
  setStreamingNumber: (streamingNumber: any) => void;

};

export const CalculatorContext = createContext<CalculatorContextProps>({
  selectedPlatforms: [],
  selectedAudioVideo: [],
  streamingInputs: [],
  calculatedValues: [],
  streamingNumber: null,
  setSelectedPlatforms: () => {},
  setSelectedAudioVideo: () => {},
  setStreamingInputs: () => {},
  setCalculatedValues:()=> {},
  setStreamingNumber: () => {},
});

type CalculatorProviderProps = {
  children: ReactNode;
};

export const CalculatorProvider: React.FC<CalculatorProviderProps> = ({ children }) => {
  const [selectedPlatforms, setSelectedPlatforms] = useState<SelectOption[]>([]);
  const [selectedAudioVideo, setSelectedAudioVideo] = useState<SelectOption[]>([]);
  const [streamingInputs, setStreamingInputs] = useState<SelectOption[]>([]);
  const [calculatedValues, setCalculatedValues] = useState<any[]>([]);
  const [streamingNumber, setStreamingNumber] = useState<any>(null);


  return (
    <CalculatorContext.Provider value={{ 
        selectedPlatforms, 
        selectedAudioVideo, 
        streamingInputs, 
        calculatedValues,
        streamingNumber, 
        setSelectedPlatforms,
        setSelectedAudioVideo, 
        setStreamingInputs, 
        setCalculatedValues,
        setStreamingNumber }}>
      {children}
    </CalculatorContext.Provider>
  );
};
